import { FC, Fragment, useEffect, useState } from "react";

import {
  Checkbox,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { StripeCardElementOptions } from "@stripe/stripe-js";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import toast from "react-hot-toast";

import PrivacyPolicy from "containers/PageHome/PrivacyPolicy";
import { globalJson } from "global/global_json";
import { useData } from "data/data-provider";

import { ProfileChangeFun } from "api/profile/profile";
import { encodeBase64 } from "@progress/kendo-file-saver";
import { getRentalAgreementContent } from "api/rental-agreement/rental-agreement";
import Cancellation from "containers/PageHome/Cancellation";
import { formatDateTime } from "utils/converSelectedDateToString";
import RentalAgreement from "containers/PageHome/RentalAgreement";
import serverURL  from "api/auth/auth";
import customAxios from "config";
import {
  getSavedPaymentMethod,
  PaymentDone,
  removeSavedPaymentMethod,
  SavePaymentMethod,
} from "api/stripe/stripe";
import moment from "moment";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const signatureStylesText = [
    "Playball",
    "Qwitcher Grypen",
    "Montez",
    "My Soul",
    "Ms Madi",
    "Niconne",
  ];

  const {
    user,
    startDate,
    endDate,
    pickupLocation,
    dropOffLocation,
    setCouponResponse,
    checkCouponResponseLoading,
    setUser,
    selectedCar,
    searchedPickupLoction,
    searchedDropoffLocation,
    bookingdetails,
    callCouponAPI,
    saveCard,
    couponResponse,
    checkout,
    callremoveCouponAPI,
  } = useData();
  const [inputValue, setInputValue] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isAgreementOpen, setIsAgreementOpen] = useState(false);
  let [isTermOpen, setIsTermOpen] = useState(false);
  let [isCancellationOpen, setIsCancellationOpen] = useState(false);
  let [rentalAgreementChecked, setRentalAgreementChecked] = useState(false);
  let [loadingDetail, setLoadingDetail] = useState(false);
  let [loading, setLoading] = useState(false);
  let [checkoutLoading, setCheckoutLoading] = useState(false);
  let [privacyPolicyCheckedChecked, setPrivacyPolicyChecked] = useState(false);
  let [agreementContent, setAgreementContent] = useState<any>([]);
  const [privacyPolicy, setPrivacyPolicy] = useState<any>([]);
  const [cancellationPolicy, setCancellationPolicy] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const [isAddCardVisible, setIsAddCardVisible] = useState(true);
  const [savedCardDetails, setSavedCardDetails] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [initialRenderComplete, setInitialRenderComplete] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_no ?? "");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      setLoadingDetail(true);

      const userId = user?.id;
      if (!userId) {
        console.error("User ID is undefined");
        setLoadingDetail(false);
        return;
      }
      const userDetails = { ...user, phone_no: phoneNumber };

      const formData = new FormData();
      formData.append("phone_no", phoneNumber || "");

      await ProfileChangeFun(formData);
      setUser(userDetails);
      localStorage.setItem("user", JSON.stringify(userDetails));
      toast.success("Phone number updated successfully!");
    } catch (error) {
      console.error("Error updating user profile:", error);
    } finally {
      setLoadingDetail(false);
    }
  };

  useEffect(() => {
    const openRentalAgreement = async () => {
      if (bookingdetails && globalJson && user?.email) {
        const encodedData = encodeBase64(user.email); // Encoding data to UTF-8
        const path = `${bookingdetails.id}b6cr4${ process.env.REACT_APP_ENV !== "prod"
          ? globalJson.dev_company_id
          : globalJson.prod_company_id}b6cr4${encodedData}`;

        try {
          const response = await getRentalAgreementContent(path);
          if (response?.data) {
            setAgreementContent(response.data);
            setPrivacyPolicy(response.data.content.privacy_policy);
            setCancellationPolicy(response.data.content.cancellation_policy);
          }
        } catch (error) {
          console.error("Error fetching rental agreement content:", error);
        }

        // setUrl(`https://dev.1now.app/pdf?agreement=${path}`);
      }
    };

    openRentalAgreement();
  }, [bookingdetails, globalJson, user]); // Add dependencies to avoid unnecessary re-renders

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleCouponSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    callCouponAPI(inputValue, bookingdetails?.id);
    setInputValue("");
  };

  const callCheckout = async () => {
    //bypass
    // navigate(`/pay-done/${bookingdetails?.id}`);
    // return;
    //
    setCheckoutLoading(true);
    // Perform validations (e.g. phone number, agreements)
    if (!user?.phone_no) {
      toast.error("Please provide your phone number");
      setCheckoutLoading(false);
      return;
    }

    // Call the backend to initiate Stripe checkout
    // const response = await checkout(bookingdetails?.id);

    // Set Stripe Intent and open the payment modal
    // setStripeIntent(response?.data);
    // setIsOpen(true);
    setCheckoutLoading(false);
  };

  useEffect(() => {
    if (inputValue == "") {
      setCouponResponse(null);
    }
  }, [inputValue]);

  // Handle payment submission
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("Card element not found.");
      setLoading(false);
      return;
    }

    // Confirm payment with the client secret from the backend
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      toast.error("Payment failed: " + error.message);
      setLoading(false);
      return;
    } else {
      sendPaymentMethodToBackend(paymentMethod.id);
    }
  };

  const sendPaymentMethodToBackend = async (paymentMethodId: any) => {
    try {
      const data = await saveCard(paymentMethodId);
      toast.success(data?.data.message);
      fetchPaymentMethod();
      setIsAddCardVisible(false);
      setLoading(false);
    } catch (error) {
      console.error("Error saving card", error);
      setLoading(false);
    }
  };

  function close() {
    setIsOpen(false);
  }
  const fetchPaymentMethod = async () => {
    try {
      const data = await getSavedPaymentMethod();
      setSavedCardDetails(data);
  
      // If there are no saved cards, show the CardElement form
      if (data?.payment_methods?.length === 0) {
        setIsAddCardVisible(true); // Show CardElement if no cards
      } else {
        setIsAddCardVisible(false); // Hide CardElement if cards exist
      }
  
      return data; 
    } catch (error) {
      console.error("Error fetching payment method:", error);
    }
  };
  
  useEffect(() => {
    if (savedCardDetails?.payment_methods?.length > 0) {
      setIsAddCardVisible(false);
    }
    fetchPaymentMethod();
  }, []);

  const handleCardSelection = (paymentMethodId: string) => {
    setSelectedCard(paymentMethodId); // Set selected card when the checkbox is checked
  };

  const payDone = async () => {
    // Validation checks
    if (!user?.phone_no) {
      toast.error("Please provide your phone number");
      return;
    }
    if (rentalAgreementChecked !== true) {
      toast.error("Please agree to the Rental Agreement");
      return;
    }
    if (privacyPolicyCheckedChecked !== true) {
      toast.error("Please agree to Privacy & Cancellation Policies");
      return;
    }
    if (selectedCard === null) {
      toast.error("Please select a card or add a new one");
      return;
    }

    setLoading(true);
    try {
      const response = await PaymentDone(selectedCard, bookingdetails.id);
      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success("Payment completed successfully!");
        navigate(`/pay-done/${bookingdetails?.id}`);
      }
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

 const removeCard = async (paymentId: any) => {
  try {
    const response = await removeSavedPaymentMethod(paymentId);
    if (response?.error) {
      toast.error("Error removing payment method: " + response.error);
    } else {
      toast.success("Payment method removed successfully");
      // Re-fetch the payment methods to update the UI
      const data = await fetchPaymentMethod();

      // If no payment methods are left, show the CardElement
      if (data?.payment_methods?.length === 0) {
        setIsAddCardVisible(true); // Show CardElement form if no saved cards
      }
    }
  } catch (error) {
    console.error("Error removing payment method:", error);
    toast.error("An error occurred while removing the payment method.");
  }
};

  

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full">
            <div className=" w-full aspect-w-4 aspect-h-2 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover w-full"
                sizes="200px"
                src={
                  (selectedCar?.fleet_photos?.length ?? 0) > 0
                    ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full lg:max-w-sm mx-auto">
            <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
              Rental Bill
            </h2>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Prices may change depending on the length of the rental and the
              price of your rental car.
            </p>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Subtotal
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {" "}
                    ($
                    {bookingdetails?.invoice?.avg_car_price_per_day} *{" "}
                    {bookingdetails?.trip_days} days)
                  </span>
                </div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.car_total}
                </span>
              </div>
            </div>

            {bookingdetails?.invoice?.location_total !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Location Total
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.location_total}
                  </span>
                </div>
              </div>
            )}

            {bookingdetails?.invoice?.extras_total !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Extras Total
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.extras_total}
                  </span>
                </div>
              </div>
            )}

            {bookingdetails?.invoice?.insurance_total !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Insurance Total
                  </span>
                  <span className="lg:text-2xl sm:text-xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.insurance_total}
                  </span>
                </div>
              </div>
            )}

            {bookingdetails?.invoice?.tax !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Tax
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.tax}
                  </span>
                </div>
              </div>
            )}

            {bookingdetails?.invoice?.discount !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Discount
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.discount}
                  </span>
                </div>
              </div>
            )}

            <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Total Rental Price
                  </span>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Overall price rental
                  </p>
                </div>
                <div>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    $
                    {bookingdetails?.invoice
                      ?.total_price_after_coupon_without_security
                      ? bookingdetails?.invoice
                          ?.total_price_after_coupon_without_security
                      : bookingdetails?.invoice?.total_price_without_security}
                  </span>
                </div>
              </div>
            </div>

            {bookingdetails?.invoice?.security_deposit_total !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Deposit Fee
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.security_deposit_total}
                  </span>
                </div>
              </div>
            )}

            {couponResponse !== null && (
              <div className="mt-4 ">
                <div className="flex justify-between items-center">
                  <div className="flex flex-wrap  border border-grey rounded-full py-1">
                    <span
                      className="text-lg font-normal text-gray-700 dark:text-gray-300 pl-4 w-28 truncate"
                      title={couponResponse?.coupon_info?.name}
                    >
                      {couponResponse?.coupon_info?.name}
                    </span>
                    <span className="text-lg text-green-600 font-medium pl-1">
                      (
                      {couponResponse?.coupon_info?.coupon_type === "amount"
                        ? `$${couponResponse?.amount} off`
                        : `${couponResponse?.coupon_percentage}% off`}
                      )
                    </span>
                    <ButtonCircle
                      onClick={() => {
                        setCouponResponse(null);
                        callremoveCouponAPI(
                          couponResponse?.coupon_info?.name,
                          bookingdetails?.id
                        );
                      }}
                      size="w-6 h-6"
                      className=" self-center mr-2 ml-4"
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </ButtonCircle>
                  </div>
                  <span className="text-2xl text-green-600 font-semibold">
                    -${couponResponse?.coupon_discount}
                  </span>
                </div>
              </div>
            )}
            {couponResponse === null && (
              <form
                onSubmit={handleCouponSubmit}
                className="mt-10 relative w-full lg:max-w-sm"
              >
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  required
                  aria-required
                  placeholder="Promo Code"
                  type="text"
                  rounded="rounded-full"
                />
                <ButtonCircle
                  type="submit"
                  className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
                >
                  {checkCouponResponseLoading ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    <i className="las la-arrow-right text-xl"></i>
                  )}
                </ButtonCircle>
              </form>
            )}
            {savedCardDetails?.payment_methods?.length > 0 && (
              <>
                <div className="mt-4">
                  <span className="text-lg text-gray-700 dark:text-gray-300 font-semibold">
                    Card Details
                  </span>
                </div>
                {savedCardDetails?.payment_methods.map(
                  (paymentMethod: any, index: any) => (
                    <div className="mt-4 border rounded-xl p-2" key={index}>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <Checkbox
                            checked={selectedCard === paymentMethod.id}
                            onChange={() =>
                              handleCardSelection(paymentMethod.id)
                            } // Handle checkbox change
                            className="group size-7 rounded-md p-1 ring-1 ring-green-200 ring-inset data-[checked]:bg-green-200 mr-4"
                          >
                            <CheckIcon
                              className={`${
                                selectedCard === paymentMethod.id
                                  ? "visible"
                                  : "invisible"
                              } w-5 h-5 text-green-600`}
                            />
                          </Checkbox>
                          {paymentMethod?.brand === "visa" ? (
                            <img
                              src="https://e7.pngegg.com/pngimages/338/270/png-clipart-logo-credit-card-visa-debit-card-credit-card-blue-text.png"
                              alt="Visa logo"
                              className="w-12 h-8 mr-4"
                            />
                          ) : paymentMethod?.brand === "mastercard" ? (
                            <img
                              src="https://banner2.cleanpng.com/20180821/jpy/69278df51f257f741f3bc6eca1c8b19a.webp"
                              alt="Mastercard logo"
                              className="w-10 h-8 mr-4"
                            />
                          ) : (
                            // Default SVG for other card types
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-4"
                            >
                              <rect
                                width="32"
                                height="32"
                                rx="8"
                                fill="#0096BE"
                              />
                              <path
                                d="M6 12.5059H26"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 20.5059H12"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.5 20.5059H18.5"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.44 7.50586H21.55C25.11 7.50586 26 8.38586 26 11.8959V20.1059C26 23.6159 25.11 24.4959 21.56 24.4959H10.44C6.89 24.5059 6 23.6259 6 20.1159V11.8959C6 8.38586 6.89 7.50586 10.44 7.50586Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          <div>
                            <p className="text-lg font-semibold text-[#164863]">
                              {paymentMethod.brand.charAt(0).toUpperCase() +
                                paymentMethod.brand.slice(1)}{" "}
                              Ending In {paymentMethod.last4}
                            </p>
                            <p className="text-sm text-[#777777]">
                              Expiry In {paymentMethod.exp_month}/
                              {paymentMethod.exp_year}
                            </p>
                          </div>
                        </div>
                        <button
                          className="text-red-500 hover:text-red-700"
                          onClick={() => removeCard(paymentMethod.id)}
                        >
                          &#10005;
                        </button>
                      </div>
                    </div>
                  )
                )}

                {!isAddCardVisible && (
                  <div className="mt-2">
                    <span
                      className="text-[#164863] font-semibold cursor-pointer"
                      onClick={() => setIsAddCardVisible(true)} // Show form when "Add Another" is clicked
                    >
                      + Add Another
                    </span>
                  </div>
                )}
              </>
            )}
            {isAddCardVisible && (
              <form onSubmit={handleSubmit}>
                <div className="mt-8">
                  <span className="text-lg text-gray-700 dark:text-gray-300 font-semibold">
                    Add New Card
                  </span>
                  <CardElement
                    id="card-element"
                    options={cardStyle}
                    className="p-3"
                  />
                </div>
                <ButtonPrimary className="w-full mt-4" type="submit">
                  {loading ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    "Add Card"
                  )}
                </ButtonPrimary>
              </form>
            )}

            {!isAddCardVisible && (
              <ButtonPrimary className="w-full mt-4" onClick={payDone}>
                {loading ? (
                  <div className="flex justify-center items-center col-span-full">
                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                  </div>
                ) : (
                  "Pay and Book"
                )}
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Trip Details</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <h3 className="text-2xl font-semibold">Location</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">Pick up Location</span>
              <span className="mt-1.5 text-lg font-semibold">
                {pickupLocation?.address}
              </span>
            </div>

            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Drop off Location
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                {dropOffLocation
                  ? dropOffLocation?.address
                  : pickupLocation?.address}
              </span>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-2xl font-semibold">Phone Number</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <label htmlFor="phone" className="text-sm text-neutral-400">
                Contact Number
              </label>
              <div className="flex mt-1.5">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="block w-full px-3 py-2 border-none rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-transparent text-lg font-semibold"
                  placeholder="Enter your phone number"
                />
                <button
                  type="button"
                  onClick={handleSave}
                  className=" bg-primary-500 text-white rounded-md text-lg font-semibold min-w-[80px]"
                >
                  {loadingDetail ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Date & Time</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Pick up Date & Time
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                {moment(startDate).format("DD/MM/YYYY hh:mm A")}
              </span>
            </div>

            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Drop off Date & Time
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                <span className="line-clamp-1">
                  {/* {formatDateTime(endDate)} */}
                  {moment(endDate).format("DD/MM/YYYY hh:mm A")}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Confirmation</h3>

          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
            <Checkbox
              checked={rentalAgreementChecked}
              onChange={(checked) => {
                setIsAgreementOpen(true);
              }}
              className="group size-7 rounded-md  p-1 ring-1 ring-checkbox-tick ring-inset data-[checked]:bg-checkbox-bg mr-4"
            >
              <CheckIcon
                className={`size-5 stroke-checkbox-tick ${
                  rentalAgreementChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I've reviewed and accepted the Rental Agreement
              </div>
              <div
                className="text-gray-800 dark:text-gray-200 text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsAgreementOpen(true);
                }}
              >
                Rental Agreement{" "}
              </div>
            </div>
          </div>
          {/* <div className="overflow-x-auto lg:hidden flex mt-6">
            {signatureStylesText.map((font, index) => (
              <div
                key={index}
                className="mr-4 w-64"
                onClick={() => {
                  setSelectedSignatureIndex(index);
                }}
              >
                <div
                  className={` border rounded-lg mb-3 p-4 flex items-center ${
                    selectedSignatureIndex === index
                      ? "border-green-600 shadow-lg"
                      : "border-gray-200"
                  }`}
                >
                  <p
                    style={{ fontFamily: font }}
                    className="text-l text-secondary w-64"
                  >
                    {`${stripeVerification?.person_details?.first_name ?? ""} ${
                      stripeVerification?.person_details?.last_name ?? ""
                    }`}
                  </p>
                </div>
              </div>
            ))}
          </div> */}
          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
            <Checkbox
              checked={privacyPolicyCheckedChecked}
              onChange={(checked) => {
                setPrivacyPolicyChecked(checked);
              }}
              className="group size-7 rounded-md  p-1 ring-1 ring-checkbox-tick ring-inset data-[checked]:bg-checkbox-bg mr-4"
            >
              <CheckIcon
                className={`size-5 stroke-checkbox-tick ${
                  privacyPolicyCheckedChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I agree to the privacy policy and cancellation policy
              </div>

              <span
                className="text-gray-800 text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsTermOpen(true);
                }}
              >
                Privacy Policy
              </span>
              <span className="text-gray-800 text-xl font-bold cursor-pointer mx-2">
                &
              </span>
              <span
                className="text-gray-800 text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsCancellationOpen(true);
                }}
              >
                Cancellation Policy
              </span>
            </div>
          </div>
        </div>
        {/* <div className="lg:hidden flex-grow">{renderSidebar()}</div> */}
        {/* <div className="px-4 py-2">
          <div className="hidden lg:flex lg:overflow-x-scroll">
            {signatureStylesText.map((font, index) => (
              <div
                key={index}
                className="mr-4 w-64"
                onClick={() => {
                  setSelectedSignatureIndex(index);
                }}
              >
                <div
                  className={` border rounded-lg mb-3 p-4 flex items-center ${
                    selectedSignatureIndex === index
                      ? "border-green-600 shadow-lg"
                      : "border-gray-200"
                  }`}
                >
                  <p
                    style={{ fontFamily: font }}
                    className="text-l text-secondary w-64"
                  >
                    {`${stripeVerification?.person_details?.first_name ?? ""} ${
                      stripeVerification?.person_details?.last_name ?? ""
                    }`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    );
  };

  const cardStyle: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  function padToTwoDigits(number: number) {
    return number.toString().padStart(2, "0");
  }

  function formatDateToCustomString(date: Date) {
    const year = date.getFullYear();
    const month = padToTwoDigits(date.getMonth() + 1); // getMonth() returns 0-based month
    const day = padToTwoDigits(date.getDate());
    const hours = padToTwoDigits(date.getHours());
    const minutes = padToTwoDigits(date.getMinutes());
    const seconds = padToTwoDigits(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  return (
    <>
      <div className={`nc-CheckOutPagePageMain ${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
            {renderMain()}
          </div>
          <div className="block flex-grow mt-12 lg:mt-0">{renderSidebar()}</div>
        </main>
      </div>

      <Transition appear show={isTermOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsTermOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsTermOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                       
                            <PrivacyPolicy terms={privacyPolicy} />
                          
                        </div>
                      </div>
                    </div>

                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setPrivacyPolicyChecked(true);
                          setIsTermOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isAgreementOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsAgreementOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsAgreementOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                  
                            <RentalAgreement data={agreementContent} />
                  
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setRentalAgreementChecked(true);
                          setIsAgreementOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isCancellationOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsCancellationOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4 z-0">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsCancellationOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                       
                            <Cancellation terms={cancellationPolicy} />
                    
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setPrivacyPolicyChecked(true);
                          setIsCancellationOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CheckOutPagePageMain;
